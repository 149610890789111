(function($){
    const fn = $(".comp_offers_slider");

    if (fn.length) {
        cssLoaded(function(){
            let slider = fn.find("[data-slider]");

            slider.flickity({
                groupCells: '25%',
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                wrapAround: true,
                autoPlay: fn.find('[data-slider]').data("slider"),
                pauseAutoPlayOnHover: true
            });
            //
            // fn.find("[data-slider-prev]").on( 'click', function() {
            //     $(this).closest(fn).find("[data-slider]").flickity('previous');
            // });
            //
            // fn.find("[data-slider-next]").on( 'click', function() {
            //     $(this).closest(fn).find("[data-slider]").flickity('next');
            // });
        });
    }
})(jQuery);