(function($){
    const fn = $(".comp_site_detail");

    if (fn.length) {
        $.getScript(cdnjs.lightgallery).done(function(){
            fn.find("[data-gallery]").lightGallery({
                thumbnail: true,
                selector: ".elm_item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                autoplayControls: true
            });
        });
    }
})(jQuery);