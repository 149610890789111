var reInitGCaptcha;

(function($) {
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LckEk4cAAAAAHrEnKlzK_j76Q8gGg8O6i7QvYG4").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LckEk4cAAAAAHrEnKlzK_j76Q8gGg8O6i7QvYG4', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });
    
    nl_lib_dialog.init();
    
    $(".part_ui_btn").nl_lib_ripple();

    if ($(".lib--rellax").length && win.width() > 960) {
        $.getScript(cdnjs.rellax).done(function() {
            new Rellax('.lib--rellax', {
                center: true
            });
        });
    }
    
    
    //offer
    doc.on('click', '[data-changeoffer]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1},
            dataType: "json",
        }).done(function(data) {
            $('[data-changeoffer]').removeClass('mod--active');
            $el.addClass('mod--active');
            fn_ajaxHandler(data);
            sr.sync();
        });
    });

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                        setInterval(function(){reInitGCaptcha();}, 150000);
                    }
                });
            }
        });
    });
    
    var antispam = function () {
        if ($('#snippet-contactform').length) {
            setTimeout(function () {
                $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };
    
    $(document).ready(function () {
        antispam();
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    
    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.getScript(cdnjs.cookieconsent);        
    }
    
    if($('.part_dialog_exit_banner').length) {
        $.getScript(cdnjs.exitbanner);
        $.getStyle("https://packages.newlogic.cz/newlogic-exitbanner/1.0.2/css/newlogic-exitbanner.min.css");
    }
    
    if($('.part_scroll_banner').length) {
        $.getScript(cdnjs.scrollbanner);
    }
})(jQuery);