(function($){
    const $fn = $(".part_form_booking");

    if ($fn.length) {
            let checkin = $('[data-form-datepicker="arrival"]');
            let checkout = $('[data-form-datepicker="departure"]');

        $.getScript(cdnjs.datepicker_lang.replace(".cs.","."+lang+".")).done(function () {
            let datepicker_in = checkin.find("[data-arrival]").datepicker({
                language: lang,
                position: "top center",
                minDate: new Date(),
                autoClose: true,
                dateFormat: 'yyyy-mm-dd',
                onSelect: function (fd, d) {
                    d.setDate(d.getDate()+1);
                    checkout.find("[data-departure]").datepicker().data('datepicker').selectDate(d);
                    checkout.find("[data-departure]").datepicker().data('datepicker').update({
                        minDate: d
                    });
                }
            }).data('datepicker');

            let datepicker_out = checkout.find("[data-departure]").datepicker({
                language: lang,
                position: "top center",
                minDate: new Date(),
                autoClose: true,
                dateFormat: 'yyyy-mm-dd'
            }).data('datepicker');

            checkin.on("click", function(){
                datepicker_in.show();
            });

            checkout.on("click", function(){
                datepicker_out.show();
            });
        });
    }
})(jQuery);