(function($){
    const fn = $(".comp_activities");

    if (fn.length) {
        let carousel = fn.find("[data-carousel]");
        cssLoaded(function(){
            carousel.flickity({
                imagesLoaded: false,
                percentPosition: false,
                setGallerySize: false,
                pageDots: true,
                wrapAround: true,
                contain: true,
                autoPlay: carousel.data("carousel"),
                prevNextButtons: false,
                pauseAutoPlayOnHover: true
            });
        });
    }
})(jQuery);