(function($){
    const fn = $(".comp_visual");

    if (fn.length) {
        cssLoaded(function() {
            let carousel = fn.find('[data-visual-slider]'),
                offers = fn.find('[data-offers-slider]'),
                autoplay = carousel.data("visual-slider");

            if(offers.children().length > 1) {
                offers.flickity({
                    contain: true,
                    pageDots: false,
                    wrapAround: true,
                    prevNextButtons: true,
                    on: {
                        ready: function() {
                            setTimeout(function(){
                                offers.flickity('resize');
                            },300);
                        },
                        change: function() {
                            offers.flickity('resize');
                        }
                    }
                });
            }

            if(carousel.children().length > 1) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false
                });
            }
        });
    }
})(jQuery);