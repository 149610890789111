(function($) {
    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "" && $(this).find("input, textarea").length) {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });

        doc.on("click",".part_ui_input [data-number]", function (e) {
            e.preventDefault();
            let elm = $(this);
            let inp = elm.closest(".part_ui_input").find("input");
            let num;

            if (elm.is('[data-number="plus"]')) {
                num = +inp.val() + inp.data('step');
                if (num <= inp.attr('max')) {
                    inp.val(num).trigger("change");
                }
            }
            if (elm.is('[data-number="minus"]')) {
                num = +inp.val() - inp.data('step');
                if (num >= inp.attr('min')) {
                    inp.val(num).trigger("change");
                }
            }
        });
    }
})(jQuery);