(function($){
    const fn = $(".comp_rooms");

    if (fn.length) {
        $.getScript(cdnjs.lightgallery).done(function(){
            fn.find("[data-gallery]").lightGallery({
                thumbnail: true,
                selector: ".elm_item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                autoplayControls: true
            });
            fn.on("click", "[data-toggle-gallery]", function(){
                fn.find("[data-gallery] .col:first-of-type .elm_item").trigger("click");
            });
        });
    }
})(jQuery);