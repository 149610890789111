(function($){
    fn_comp_gallery($);
})(jQuery);

function fn_comp_gallery($) {
    const fn = $(".comp_gallery");

    if (fn.length) {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
            $.getScript(cdnjs.masonry).done(function () {
                cssLoaded(function() {
                    fn.find(".wrp_comp_body").masonry({
                        itemSelector: '.col',
                        columnWidth: fn.find(".wrp_comp_body").data("masonry-col"),
                        percentPosition: true
                    });
                })
            });
        }
        $.getScript(cdnjs.lightgallery).done(function(){
            if (typeof fn.find("[data-gallery]").data('lightGallery') !== "undefined") {
                fn.find("[data-gallery]").data('lightGallery').destroy(true);
            }
            fn.find("[data-gallery]").lightGallery({
                thumbnail: true,
                selector: ".part_item_gallery",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                autoplayControls: true
            });
        });
    }
}

doc.on('click', '[data-changegallery]', function (e) {
    e.preventDefault();
    var el = $(this);
    $.ajax({
        url: el.attr('href'),
        data: {ajax: 1},
        dataType: "json",
    }).done(function(data) {
        $('[data-changegallery]').removeClass('mod--active');
        el.addClass('mod--active');
        fn_ajaxHandler(data);
        sr.sync();
        fn_comp_gallery($);
    });
});

doc.on('click', '[data-resizegallery]', function (e) {
    e.preventDefault();
    var el = $(this);
    $.ajax({
        url: el.attr('data-href'),
        data: {ajax: 1},
        dataType: "json",
    }).done(function(data) {
        var newItems = $(data.snippets['snippet-gallery']);
        var itemsHtml = newItems.find('#gallery-wraper').html();
        $('#gallery-wraper').append(itemsHtml);

        var itemsButton = newItems.find('#button-more').html();
        $('#button-more').html(itemsButton);

        sr.sync();
        fn_comp_gallery($);
    });
});